import * as Bananas from "bananas-commerce-admin";
import { useQuery } from "@tanstack/react-query";

import * as Sentry from "@sentry/react";
import { useSnackbar } from "notistack";

import { Case } from "@/extensions/klarahill/types";

import { Nullish } from "@/utils/types";

const useCaseQuery = (caseId: Nullish<Case["id"]>) => {
  const api = Bananas.useApi();
  const { enqueueSnackbar } = useSnackbar();

  const c = useQuery({
    queryKey: ["case", "c", caseId],
    queryFn: async () => {
      const action = api.operations["case.case:retrieve"];
      if (!action) throw new Error('No action found for "case.case:retrieve"');

      const response = await action.call({ params: { case_id: caseId } });
      if (response.ok) {
        const c: Case = await response.json();
        return c;
      } else {
        enqueueSnackbar("Kunde inte hämta begravningsärende.", {
          variant: "error",
        });
        Sentry.captureException(new Error("Failed to fetch case"));
        return null;
      }
    },
  });

  return c;
};

export default useCaseQuery;
